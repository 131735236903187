import { Controller } from "@hotwired/stimulus";

import restaurantMarkerIcon from "../../images/map/restaurant-marker-icon.svg";
import customerMarkerIcon from "../../images/map/customer-marker-icon.svg";

export default class extends Controller {
  static targets = ["map"];

  declare mapTarget: HTMLDivElement;

  map?: google.maps.Map;
  locationMarker?: google.maps.Marker;
  infoWindow?: google.maps.InfoWindow;

  async connect(): Promise<void> {
    await google.maps.importLibrary("maps");
    this.createMap();
  }

  createMap(): void {
    const location = {
      lat: parseFloat(this.data.get("latitude") ?? "0"),
      lng: parseFloat(this.data.get("longitude") ?? "0")
    };

    this.map = new google.maps.Map(this.mapTarget, {
      center: location,
      zoom: 14,
      maxZoom: 17,
      gestureHandling: "cooperative",
      keyboardShortcuts: false,
      styles: [
        // turn off all markers to reduce clutter, particularly from irrelevant businesses,
        // then turn park names back on to help people orient
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [{ "visibility": "off" }]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [{ "visibility": "on" }]
        },
      ],
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    });

    this.locationMarker = new google.maps.Marker({
      map: this.map,
      position: location,
      title: this.data.get("street-address"),
      zIndex: 2,
      icon: {
        url: this.data.get("fulfillment") == "pickup" ? restaurantMarkerIcon : customerMarkerIcon,
        scaledSize: new google.maps.Size(32, 32),
        anchor: new google.maps.Point(24, 24),
      },
    });

    this.infoWindow = new google.maps.InfoWindow({
      zIndex: 1,
      headerDisabled: true,
      content: `<div class="custom-map-popover">${this.data.get("fulfillment") == "pickup" ? 'Pickup from' : 'Delivering to'}<br/><strong>${this.data.get("suburb-and-city")}</strong></div>`,
    });
    this.infoWindow.open(this.map, this.locationMarker);
 }
}