import React, { useCallback, useEffect, useState } from "react";

import { PaymentDetails } from "./payment_details";
import { TermsAndConditions } from "./terms_and_conditions";
import { PayButton } from "./pay_button";
import { DividerHeader } from "./divider_header";
import { Stripe, StripeElements, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { QueryClient, QueryClientProvider, useMutation } from "@tanstack/react-query";
import { submitPayment, SubmitPaymentParams, STRIPE_MINIMUM_CHARGE } from "src/checkout/submit_payment";
import { parsePaymentMethods } from "./decoders";
import { PaymentMethod } from "./model";
import { createPaymentMethod } from "./create_payment_method";
import { ReactComponent as AlertCircleIcon } from "../../images/icons/alert-circle.svg";
import { ReactComponent as RemoveIcon } from "../../images/icons/remove.svg";
import { ReactComponent as LoadingSpinner } from "images/animated/loading_spinner.svg";
const queryClient = new QueryClient();

const Loading = () => {
  return (
    <section className="container width-constraint-narrow space-ml-none">
      <div className="skeleton is-heading"></div>
      <div className="skeleton is-heading"></div>
      <div className="skeleton is-heading"></div>
      <div className="skeleton is-heading"></div>
      <div className="skeleton is-paragraph" style={{ "--lines": 1 } as React.CSSProperties}></div>
    </section>
  );
}

const Checkout = ({
  paymentAmountInCents,
  formElement,
  billingName,
  billingEmail,
  shouldTakePayment,
  allowSaveCard,
  enabledPaymentMethods,
  confirmPath,
  stripe = null,
  elements = null,
} : {
  paymentAmountInCents?: number;
  formElement: HTMLFormElement;
  billingName: string;
  billingEmail: string;
  shouldTakePayment: boolean;
  allowSaveCard: boolean;
  enabledPaymentMethods: string[];
  confirmPath: string;
  stripe: Stripe | null;
  elements: StripeElements | null;
}) => {

  const [savedPaymentMethods, setPaymentMethods] = useState<PaymentMethod[] | undefined>(undefined);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>(undefined);
  const [paymentDetailsComplete, setPaymentDetailsComplete] = useState<boolean>(false);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState<boolean>(false);
  const [saveCard, setSaveCard] = useState<boolean>(true);
  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState<string | undefined>(undefined);
  const [verifyPending, setVerifyPending] = useState<boolean>(false);
  const [verifyError, setVerifyError] = useState<string | undefined>(undefined);

  const {
    mutate: submitPaymentMutation,
    isPending: isLoadingSubmitPayment,
    isSuccess: isSuccessPayment,
    isError: isErrorSubmitPayment,
    error: errorSubmitPayment,
    reset: resetSubmitPayment
  } = useMutation<void, Error, SubmitPaymentParams>( { mutationFn: submitPayment })

  const fetchPaymentMethods = async () : Promise<PaymentMethod[]> => {
    const resp = await fetch("/settings/payment_methods", {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    });

    if (!resp.ok)
      return [];

    const json = await resp.json();
    return parsePaymentMethods(json);
  }

  useEffect(() => {
    fetchPaymentMethods().then((paymentMethods: PaymentMethod[]) => {
      if (paymentMethods.length > 0) {
        const orderedPaymentMethodsByCardDefault = paymentMethods.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
        setSelectedPaymentMethod(orderedPaymentMethodsByCardDefault[0]);
        setPaymentDetailsComplete(true)
      }

      setPaymentMethods(paymentMethods);
    });
  }, [setPaymentMethods]);

  const handlePaymentSubmit = useCallback(async () => {
    if (paymentAmountInCents === undefined) throw new Error("Trying to confirm an order without an order amount");

    if (shouldTakePayment) {
      if (selectedPaymentMethod === undefined && paymentDetailsComplete) {
        if (!stripe || !elements)
          throw new Error("Stripe can't be found during paymentMethod creation");

        const newPaymentMethod = await createPaymentMethod({
          stripe: stripe,
          elements: elements,
          billingName: billingName,
          billingEmail: billingEmail,
          saveCard: saveCard,
        });

        submitPaymentMutation({
          stripe: stripe,
          paymentAmountInCents: paymentAmountInCents,
          paymentMethod: newPaymentMethod,
          confirmPath: confirmPath,
          formData: new FormData(formElement),
          handleVerifyPhoneNumber: handleVerifyPhoneNumber,
        });
      } else if (selectedPaymentMethod !== undefined) {
        submitPaymentMutation({
          stripe: stripe,
          paymentAmountInCents: paymentAmountInCents,
          paymentMethod: selectedPaymentMethod,
          confirmPath: confirmPath,
          formData: new FormData(formElement),
          handleVerifyPhoneNumber: handleVerifyPhoneNumber,
        });
      } else {
        throw new Error("No saved method or new payment method specified during confirm");
      }
    } else {
      submitPaymentMutation({
        stripe: null,
        paymentAmountInCents: paymentAmountInCents,
        confirmPath: confirmPath,
        formData: new FormData(formElement),
        handleVerifyPhoneNumber: handleVerifyPhoneNumber,
      });
    }
  }, [
      billingEmail, billingName, elements, formElement,
      confirmPath, paymentAmountInCents, paymentDetailsComplete,
      selectedPaymentMethod, shouldTakePayment, stripe,
      submitPaymentMutation, saveCard
    ]);
  
  if (!savedPaymentMethods) return <Loading></Loading>;

  const header = () => {
    if (shouldTakePayment)
      return <DividerHeader />

    return null;
  };

  const updatePaymentDetailsComplete = (isComplete: boolean) => {
    setPaymentDetailsComplete(isComplete);
  }

  const setSelectedPaymentMethodHandler = async (newPaymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(newPaymentMethod);

    return undefined;
  }

  const paymentDetails = () => {
    if (!shouldTakePayment)
        return null;

    return (
      <PaymentDetails
        savedPaymentMethods={savedPaymentMethods}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethodHandler}
        setPaymentDetailsComplete={updatePaymentDetailsComplete}
        billingName={billingName}
        billingEmail={billingEmail}
        allowSaveCard={allowSaveCard}
        onSaveCardChange={setSaveCard}
        enabledPaymentMethods={enabledPaymentMethods}
      />
    );
  };

  const divider = () => {
    if (shouldTakePayment)
      return <hr />;

    return null;
  }

  const submitPaymentError = () => {
    if (!isErrorSubmitPayment) return null;

    return (
      <section className="container width-constraint-narrow space-ml-none">
        <div className="callout has-border">
          <AlertCircleIcon className="icon streamline-icon" />
          <div>{errorSubmitPayment.message}</div>
        </div>
      </section>
    )
  }

  const handleVerifyPhoneNumber = (verifyPhoneNumber: string, error: string) => {
    setVerifyPhoneNumber(verifyPhoneNumber);
    setVerifyPending(false);
    setVerifyError(error == "" ? undefined : error);
  }

  const cancelVerifyPhoneNumber = () => {
    setVerifyPhoneNumber(undefined);
    setVerifyPending(false);
    setVerifyError(undefined);
    resetSubmitPayment();
  }

  const verificationCodeEntryInput = (event: React.FormEvent<HTMLInputElement>) => {
    setVerifyError(undefined);
    if (/^[0-9]{6}$/.test((event.target as HTMLInputElement).value)) {
      setVerifyPending(true);
      handlePaymentSubmit();
    }
  }

  const verifyPhoneNumberModal = () => {
    if (!verifyPhoneNumber) return null;
    const verifyErrorMessage =
      verifyError ? <><div className="is-center-aligned-text is-error-text">{verifyError}</div></> : <></>
    return (
      <div className="modal is-forced is-active" onClick={(event) => event.currentTarget == event.target && cancelVerifyPhoneNumber()}>
        <div className="card">
          <section className="container is-stuck-bottom">
            <div className="is-flexbox">
              <h4>Can I get your digits?</h4>
              <div className="is-floating-button is-floating-right is-visible-above-desktop">
                <a className="button is-icon-only is-raised" aria-label="close" onClick={cancelVerifyPhoneNumber}>
                  <RemoveIcon className="icon streamline-icon"/>
                </a>
              </div>
            </div>
            <div className="space-mt-sm">We just need to check we have the right phone number in case we need to contact you about your order.</div>
            <div className="space-mt-sm">Please enter the 6-digit verification code we&apos;ve sent to {verifyPhoneNumber}:</div>
            <div className="space-mt-lg is-center-aligned-text">
              <div className="text-input is-inline has-hidden-label has-end-icon is-muted-icon has-monospaced-numbers">
                <label htmlFor="verification_code">Verification code</label>
                <span>
                  <input
                    name="verification_code" type="text" maxLength={6} inputMode="numeric" autoFocus={true} style={{ fontSize: '2rem', letterSpacing: '1rem', margin: '0 auto', padding: '0 4rem' }}
                    onKeyPress={(event) => /[0-9]/.test(event.key) || event.preventDefault()}
                    onInput={verificationCodeEntryInput}
                  />
                  {verifyPending ? <>
                    <span className="icon custom-icon">
                      <LoadingSpinner />
                    </span></> : <></>}
                </span>
              </div>

              {verifyErrorMessage}

              <button className="button space-mt-sm is-hidden-above-desktop" aria-label="Cancel" onClick={cancelVerifyPhoneNumber}>Cancel</button>
            </div>
          </section>
        </div>
      </div>
    );
  }

  const validPaymentMethod = paymentDetailsComplete && (selectedPaymentMethod === undefined || !selectedPaymentMethod.hasExpired());
  const isReadyToPay = (validPaymentMethod || !shouldTakePayment) && termsAndConditionsChecked && !isLoadingSubmitPayment && !isSuccessPayment;

  return (
    <>
      {header()}

      {paymentDetails()}

      <section className="section">
        {submitPaymentError()}
        {verifyPhoneNumberModal()}

        {divider()}

        <div className="container">
          <TermsAndConditions checked={termsAndConditionsChecked} setTermsAndConditionsChecked={setTermsAndConditionsChecked} />
          <PayButton shouldTakePayment={shouldTakePayment} paymentType={selectedPaymentMethod?.type} enabled={isReadyToPay} handlePaymentSubmit={handlePaymentSubmit} />
        </div>
      </section>
    </>
  );
}

const CheckoutContainer = ({
  paymentAmountInCents,
  stripe,
  formElement,
  afterpayEnabled,
  billingName,
  billingEmail,
  allowSaveCard,
  confirmPath,
} : {
  paymentAmountInCents: number;
  stripe: Stripe | null;
  formElement: HTMLFormElement;
  afterpayEnabled: boolean;
  billingName: string;
  billingEmail: string;
  allowSaveCard: boolean;
  confirmPath: string;
}) => {
  const enabledPaymentMethods = () : string[] => {
    return ["card"].concat(afterpayEnabled ? ["afterpay_clearpay"] : []);
  }

  const elementOptions : StripeElementsOptions = {
    paymentMethodCreation: 'manual',
    mode: 'payment',
    currency: 'nzd',
    amount: paymentAmountInCents,
    paymentMethodTypes: enabledPaymentMethods(),
  };

  const shouldTakePayment = paymentAmountInCents >= STRIPE_MINIMUM_CHARGE;

  const params = {
    formElement: formElement,
    paymentAmountInCents: paymentAmountInCents,
    billingName: billingName,
    billingEmail: billingEmail,
    shouldTakePayment: shouldTakePayment,
    allowSaveCard: allowSaveCard,
    enabledPaymentMethods: enabledPaymentMethods(),
    confirmPath: confirmPath,
  };

  const CheckoutInner = () => {
    return (
      <Checkout {...params} stripe={useStripe()} elements={useElements()}></Checkout>
    );
  }


  if (shouldTakePayment) {
    return (
      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripe} options={elementOptions}>
          <CheckoutInner />
        </Elements>
      </QueryClientProvider>
    );
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <Checkout {...params} stripe={null} elements={null}></Checkout>
      </QueryClientProvider>
    );
  }
}

export { CheckoutContainer, Checkout }
