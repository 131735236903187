import { Controller } from "@hotwired/stimulus";
import { createConsumer } from '@rails/actioncable';

import { OrderStatus } from "src/order_status";
import { OrderInfo } from "src/order_info";
import OrderTrackerMap from "src/order_tracker_map";

enum TrackingState {
  NoData,
  UpNext,
  LocationKnown,
}

interface DeliveryTrackingMessage {
  deliveryId: number;
  latitude?: number;
  longitude?: number;
  secondsToDropoff?: number;
  route?: string[];
}

interface OrderStatusMessage {
  deliveryId?: number;
  orderStatus: OrderStatus;
  orderStatusPreheader: string;
  orderStatusTitle: string;
  orderStatusDescription: string;
  progressStep: string;
  askForReview: boolean;
}

interface LinkMessage {
  link: string;
}

export default class OrderTrackerController extends Controller {
  static targets = ["map", "progress", "preheader", "title", "description", "appReviewModal", "orderConfirmationModal", "confirmingIcon", "preparingIcon", "readyForPickupIcon", "pickedUpIcon", "outForDeliveryIcon", "deliveredIcon", "downloadInvoice"];

  declare hasMapTarget: boolean;

  declare mapTarget: HTMLElement;
  declare progressTarget : HTMLElement;
  declare hasProgressTarget: boolean;
  declare preheaderTarget : HTMLElement;
  declare titleTarget : HTMLElement;
  declare descriptionTarget : HTMLElement;

  declare confirmingIconTarget : HTMLElement;
  declare preparingIconTarget : HTMLElement;
  declare readyForPickupIconTarget : HTMLElement;
  declare pickedUpIconTarget : HTMLElement;
  declare outForDeliveryIconTarget : HTMLElement;
  declare deliveredIconTarget : HTMLElement;
  declare downloadInvoiceTarget : HTMLElement;
  declare hasDownloadInvoiceTarget: boolean;

  declare appReviewModalTarget: HTMLElement;
  declare hasAppReviewModalTarget: boolean;

  declare orderConfirmationModalTarget: HTMLElement;
  declare hasOrderConfirmationModalTarget: boolean;

  readonly ARRIVING_THRESHOLD = 30; // seconds

  // this is always initialized after connect() finishes, but that's not a constructor so
  // TypeScript doesn't consider that enough to make them non-nullable automatically; it's
  // marked as ! here so that we don't need to do that everywhere.
  orderInfo!: OrderInfo;

  cable?: ActionCable.Consumer;
  subscription?: ActionCable.Subscription;

  trackingState: TrackingState = TrackingState.NoData;
  secondsRemaining?: number;
  arriving = false;

  orderStatus = OrderStatus.Confirming;
  loadedStatus = false;
  deliveryId?: number;

  // only set up if there's a map target to render it into
  map?: OrderTrackerMap;

  async connect(): Promise<void> {
    if (!this.hasProgressTarget) return;

    window.dataLayer = window.dataLayer || [];

    this.orderInfo = (JSON.parse(this.data.get("order-info")!) as OrderInfo);
    this.orderStatus = this.orderInfo.orderStatus;

    // if we're going to show the map, we want to wait for the googleMapsCallback to come through before we start
    // tracking so the map doesn't miss anything; if we're not, we should just go ahead and start tracking right away
    if (!this.hasMapTarget) {
      this.startTracking();
    } else {
      await google.maps.importLibrary("maps");
      this.createMap();
    }
  }

  disconnect(): void {
    this.subscription?.unsubscribe();
  }

  createMap(): void {
    // If the order's delivered, we don't show the map, nor if we're just doing the minimized progress strip at the bottom of the restaurants page.
    if (!this.hasMapTarget) return;
    this.map = new OrderTrackerMap(this.mapTarget, this.orderInfo);
    this.zoomMapForStatus();

    // We don't connect until maps is loaded so that we have somewhere to send the data.
    this.startTracking();
  }

  private startTracking(): void {
    const handleLink = this.handleLink.bind(this);
    const handleOrderStatus = this.handleOrderStatus.bind(this);
    const handleDeliveryTracking = this.handleDeliveryTracking.bind(this);
    this.cable = createConsumer('/cable');
    this.subscription = this.cable.subscriptions.create({ channel: "Customer::OrderTrackingChannel", secure_token: this.data.get("secure-token") ?? "" }, {
      received(data: object) {
        if ("link" in data) {
          handleLink(data as LinkMessage);
        } else if ("orderStatus" in data) {
          handleOrderStatus(data as OrderStatusMessage);
        } else {
          handleDeliveryTracking(data as DeliveryTrackingMessage);
        }
      },
    });
  }

  private handleLink(link: LinkMessage): void {
    window.location.href = link.link;
  }

  private handleOrderStatus(status: OrderStatusMessage): void {
    const event = this.loadedStatus ? 'tracking_status_update' : 'tracking_status_loaded';
    window.dataLayer.push({ event: event, transaction_id: this.orderInfo.id, order_status: status.orderStatus });

    this.loadedStatus = true;
    this.deliveryId = status.deliveryId;
    this.orderStatus = status.orderStatus;

    this.progressTarget.setAttribute("style", `--step: ${status.progressStep};`);
    this.preheaderTarget.innerText = status.orderStatusPreheader;
    this.titleTarget.innerText = status.orderStatusTitle;
    this.descriptionTarget.innerText = status.orderStatusDescription;

    if (this.hasDownloadInvoiceTarget && (status.orderStatus == OrderStatus.Delivered || this.orderStatus == OrderStatus.PickedUp)) {
      this.downloadInvoiceTarget.dataset["action"] = "";
    }

    if (this.hasAppReviewModalTarget && window.RequestReview !== undefined && status.askForReview && !this.showingOrderConfirmationModal()) {
      this.showAppReviews();
    }

    this.updateTitleWithETA();
    this.showIconForStatus();
    this.showMapElementsForStatus();
    this.zoomMapForStatus();
  }

  private handleDeliveryTracking(message: DeliveryTrackingMessage): void {
    // Ignore location messages from deliveries that are not the current one according to the order status data.
    // Remake tracking coming soon...
    if (message.deliveryId != this.deliveryId) {
      return;
    }

    if (message.route) {
      this.map?.setRoute(message.route);
    }

    // when the driver app has found a route to the customer, we'll get a time estimate;
    // otherwise it'll be null (but usually only for one or two location signals at a time).
    // it seems better to keep the last known ETA rather than flick back to the generic message.
    if (message.secondsToDropoff != undefined) {
      this.secondsRemaining = message.secondsToDropoff;
    }

    if (message.latitude && message.longitude) {
      // tracking the driver's location now
      if (this.trackingState != TrackingState.LocationKnown) {
        this.trackingState = TrackingState.LocationKnown;
        window.dataLayer.push({ event: 'tracking_now', transaction_id: this.orderInfo.id });
      }

      this.map?.updatePosition({ lat: message.latitude, lng: message.longitude });

      if (!this.arriving && this.secondsRemaining != undefined && this.secondsRemaining < this.ARRIVING_THRESHOLD) {
        this.arriving = true;
        this.zoomMapForStatus();
      }
    } else if (message.secondsToDropoff !== undefined) {
      // this happens when the driver has picked up the order but is delivering another order first;
      // we can give an ETA, but choose not to show their location as they go to another customer
      if (this.trackingState != TrackingState.UpNext) {
        this.trackingState = TrackingState.UpNext;
        window.dataLayer.push({ event: 'tracking_up_next', transaction_id: this.orderInfo.id });
      }

      this.map?.clearPosition();

      this.arriving = false;
    }

    this.updateTitleWithETA();
    this.showMapElementsForStatus();
  }

  private zoomMapForStatus(): void {
    if (!this.map) return;

    if (this.orderInfo.pickupOrder) {
      this.map.setCenter(this.orderInfo.restaurantLocation);
    } else if (this.orderStatus == OrderStatus.Delivered) {
      this.map.setCenter(this.orderInfo.deliveryLocation);
    } else if (this.orderStatus != OrderStatus.OutForDelivery) {
      this.map.setCenter(this.orderInfo.restaurantLocation);
    } else if (this.arriving) {
      this.map.setArrivingBounds();
    } else {
      this.map.setOutForDeliveryBounds();
    }
  }

  private showMapElementsForStatus(): void {
    if (!this.map) return;

    if (this.orderInfo.pickupOrder) {
      this.map.hideRouteAndDriverMarker();
      this.map.showRestaurantMarker();
      this.map.hideOverlayMessage();
    } else if (this.orderStatus == OrderStatus.Delivered) {
      // All done, remove everything except the delivery marker
      // (or the restaurant marker if we don't know the delivery location)
      this.map.hideRouteAndDriverMarker();
      this.map.hideRestaurantMarker();
      this.map.hideOverlayMessage();
    } else if (this.orderStatus != OrderStatus.OutForDelivery) {
      // Preparing order, generally
      this.map.hideRouteAndDriverMarker();
      this.map.showRestaurantMarker();
      this.map.hideOverlayMessage();
    } else if (this.trackingState == TrackingState.NoData) {
      // In OutForDelivery but waiting for the first location fix to come in
      this.map.hideRouteAndDriverMarker();
      this.map.showRestaurantMarker();
      this.map.showNoDataMessage();
    } else if (this.trackingState == TrackingState.UpNext) {
      // Out for delivery, but up next, so we have live ETA but not live driver location tracking
      this.map.hideRouteAndDriverMarker();
      this.map.showRestaurantMarker();
      this.map.showUpNextMessage();
    } else {
      // Live driver location tracking, this is what we're here for!
      this.map.showRouteAndDriverMarker();
      this.map.hideRestaurantMarker();
      this.map.hideOverlayMessage();
    }
  }

  private updateTitleWithETA(): void {
    if (this.orderStatus == OrderStatus.OutForDelivery) {
      if (this.arriving) {
        this.titleTarget.innerText = "Arriving now";
      } else if (this.secondsRemaining != undefined) {
        const minutes = Math.max(Math.ceil(this.secondsRemaining/60), 1);
        this.titleTarget.innerText = minutes + (minutes == 1 ? " minute" : " minutes") + " away";
      }
    }
  }

  private showIconForStatus(): void {
    // NB. this should kept matching the .step-description elements in customer/orders/success.html.erb
    this.confirmingIconTarget.classList.add("is-completely-hidden");
    this.preparingIconTarget.classList.add("is-completely-hidden");
    this.readyForPickupIconTarget.classList.add("is-completely-hidden");
    this.pickedUpIconTarget.classList.add("is-completely-hidden");
    this.outForDeliveryIconTarget.classList.add("is-completely-hidden");
    this.deliveredIconTarget.classList.add("is-completely-hidden");

    if (this.orderStatus == OrderStatus.Confirming) {
      this.confirmingIconTarget.classList.remove("is-completely-hidden");
    } else if (this.orderStatus == OrderStatus.Preparing) {
      this.preparingIconTarget.classList.remove("is-completely-hidden");
    } else if (this.orderStatus == OrderStatus.ReadyForPickup) {
      this.readyForPickupIconTarget.classList.remove("is-completely-hidden");
    } else if (this.orderStatus == OrderStatus.PickedUp) {
      this.pickedUpIconTarget.classList.remove("is-completely-hidden");
    } else if (this.orderStatus == OrderStatus.OutForDelivery) {
      this.outForDeliveryIconTarget.classList.remove("is-completely-hidden");
    } else if (this.orderStatus == OrderStatus.Delivered) {
      this.deliveredIconTarget.classList.remove("is-completely-hidden");
    }
    // if Cancelled, don't show any icon
  }

  private showingOrderConfirmationModal(): boolean {
    return (this.hasOrderConfirmationModalTarget == false || (this.hasOrderConfirmationModalTarget && !this.orderConfirmationModalTarget.classList.contains('is-active')));
  }

  private showAppReviews(): void {
    this.appReviewModalTarget.classList.add("is-active");
    this.appReviewModalTarget.classList.remove("is-completely-hidden");
  }
}
